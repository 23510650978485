/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import { useState, useMemo, createRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form/dist/index.ie11'
import formatError from '../../utilities/formatError'
import { TextField } from '../textfield'
import { Button } from '../button'
import { Link } from '../typography/Link'
import { Heading } from '../typography/Heading'
import { Stack } from '../stack'
import { Tabs } from '../tabs'
import * as styles from './Login.module.scss'

const Login = ({ className, activeTab, onSetActiveTabUrl }) => {
  const [t] = useTranslation()

  const personalValidationSchema = {
    userid: {
      required: t('Login.validationMessages.usernameRequired', {
        defaultValue: 'Please enter a username',
      }),
    },
    password: {
      required: t('Login.validationMessages.passwordRequired', {
        defaultValue: 'Please enter a password',
      }),
    },
  }

  const { errors: errorsPersonal } = useForm()

  const inputRefs = useMemo(() => Array.from({ length: 3 }).map(() => createRef()), [])

  // We should use useAutofocus hook here but there's a bug in Reach UI Tabs
  // which prevents us from focusing the input on initial page load.
  // Queueing an update via setTimeout works around this issue. We should
  // revisit at some point based on the status of the following issue:
  // ref: https://github.com/reach/reach-ui/issues/561
  useEffect(() => {
    const timer = setTimeout(() => {
      inputRefs[activeTab].current.focus()
    }, 0)
    return () => clearInterval(timer)
  }, [inputRefs[activeTab]])

  const classes = classNames(className, styles.Login)

  return (
    <div className={classes} data-qa="login">
      <div className={styles.loginTopDecorativeBackground}>
        <Heading element="h1" size={3} className={styles.LoginTitle}>
          {t('Login.title', { defaultValue: 'Member Login' })}
        </Heading>
      </div>
      {/* We use manual activation b/c changing the active tab focuses the first input */}
      {/* Don't change this back to auto because it will prevent screen reader users */}
      {/* From being able to focus 2nd and 3rd tab due to focus being changed constantly */}
      <Tabs defaultIndex={activeTab} keyboardActivation="manual" onChange={onSetActiveTabUrl}>
        <div className={styles.tabBackground}>
          <Tabs.List className={styles.LoginTabList}>
            <Fragment>
              <Tabs.Tab className={styles.LoginTab}>
                {t('Login.alkamiDigitalTab', { defaultValue: 'Digital Banking' })}
              </Tabs.Tab>
              <Tabs.Tab className={styles.LoginTab}>
                {t('Login.investmentTabLabel', { defaultValue: 'Investment' })}
              </Tabs.Tab>
            </Fragment>
          </Tabs.List>
        </div>
        <Tabs.Panels>
          <Fragment>
            <Tabs.Panel>
              <form
                method="post"
                action={process.env.ALKAMI_LOGIN}
                className={styles.LoginForm}
                autoComplete="off"
                noValidate
                data-qa="login-form"
                encType="application/x-www-form-urlencoded"
              >
                <TextField
                  type="text"
                  label={t('Login.usernameFieldLabel', { defaultValue: 'Username' })}
                  name="UserName"
                  id="UserName"
                  error={formatError(errorsPersonal.userid, personalValidationSchema.userid, t)}
                  autoComplete="username"
                />
                <TextField
                  type="password"
                  label={t('Login.passwordFieldLabel', { defaultValue: 'Password' })}
                  name="Password"
                  id="Password"
                  error={formatError(errorsPersonal.password, personalValidationSchema.password, t)}
                  autoComplete="off"
                />
                <Button variant="primary" type="submit" alt="Log in to view account" fullWidth>
                  {t('Login.loginButtonLabel', { defaultValue: 'Log In' })}
                </Button>
              </form>
              <Stack distribution="center" className={styles.LoginActions}>
                <span>
                  {t('Login.forgotCapitolCase', { defaultValue: 'Forgot' })}&nbsp;
                  <Link to={process.env.ALKAMI_FORGOT_USER} className={styles.HorizontalLink}>
                    {t('Login.usernameLowerCase', { defaultValue: 'username' })}
                  </Link>
                  &nbsp;{t('Login.orConjunction', { defaultValue: 'or' })}&nbsp;
                  <Link to={process.env.ALKAMI_FORGOT_PW} className={styles.HorizontalLink}>
                    {t('Login.forgotPasswordLinkLabel', { defaultValue: 'password' })}
                  </Link>
                  ?
                </span>
              </Stack>
              {process.env.ALLOW_ENROLL && (
                <Stack distribution="center" className={styles.LoginActions}>
                  <Link to={process.env.ALKAMI_ENROLL} className={styles.HorizontalLink}>
                    {t('Login.enrollmentLinkLabel', {
                      defaultValue: 'New to digital banking? Enroll now.',
                    })}
                  </Link>
                </Stack>
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              <Stack distribution="center">
                <Button
                  variant="primary"
                  url="https://mpv3.orcasnet.com/#/login/UC"
                  fullWidth
                  ref={inputRefs[2]}
                >
                  {t('Login.viewPortfolioLinkLabel', { defaultValue: 'View Your Portfolio' })}
                </Button>
              </Stack>
            </Tabs.Panel>
          </Fragment>
        </Tabs.Panels>
      </Tabs>
    </div>
  )
}

Login.propTypes = {
  className: PropTypes.string,
  activeTab: PropTypes.number,
  onSetActiveTabUrl: PropTypes.func,
  inputRefs: PropTypes.array,
}

export { Login }
export default Login
