import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Tabs as ReachTabs,
  TabList as ReachTabList,
  Tab as ReachTab,
  TabPanels as ReachTabPanels,
  TabPanel as ReachTabPanel,
} from '@reach/tabs'
import * as styles from './Tabs.module.scss'

const Tabs = ({ children, className, ...props }) => {
  return (
    <ReachTabs className={classNames(className)} data-qa="tabs" {...props}>
      {children}
    </ReachTabs>
  )
}

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const TabList = ({ children, className, ...props }) => {
  return (
    <ReachTabList className={classNames(styles.TabList, className)} data-qa="tabs-list" {...props}>
      {children}
    </ReachTabList>
  )
}

TabList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const Tab = ({ children, className, ...props }) => {
  return (
    <ReachTab className={classNames(styles.Tab, className)} data-qa="tabs-tab" {...props}>
      {children}
    </ReachTab>
  )
}

Tab.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const TabPanels = ({ children, className, ...props }) => {
  return (
    <ReachTabPanels className={classNames(className)} data-qa="tabs-panels" {...props}>
      {children}
    </ReachTabPanels>
  )
}

TabPanels.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const TabPanel = ({ children, className, ...props }) => {
  const { isSelected } = props
  return (
    <ReachTabPanel
      className={classNames(styles.TabPanel, className, isSelected ? styles.TabPanelActive : '')}
      data-qa="tabs-panel"
      {...props}
    >
      {children}
    </ReachTabPanel>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isSelected: PropTypes.bool,
}

Tabs.List = TabList
Tabs.Tab = Tab
Tabs.Panels = TabPanels
Tabs.Panel = TabPanel

export { Tabs }
export default Tabs
