import { useCallback } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { navigate } from '@reach/router'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { Layout } from '../components/layouts/layout'
import { Login } from '../components/login'
import { Container } from '../components/grid'
import { PageMeta } from '../components/page-meta'
import { StaticTemplate } from '../templates/staticTemplate'
import normalizePagePath from '../utilities/normalizePagePath'
import * as styles from './login.module.scss'

const LoginPage = ({ location, pageContext }) => {
  const [t, i18n] = useTranslation()
  let pageTitle = t('Login.MemberPageTitle', { defaultValue: 'Member Log In' })
  let activeTab = 0
  let pagePath = '/login'
  let backgroundImagePath =
    '//images.ctfassets.net/ekto664lcskf/56tj475cLrZBlddtmglhhv/032b2fbad7ea8a029f3e7bc18f433aae/Longs-Peak---Ben-Edits-smaller.png?q=80&w=1400'

  const loginType = location.search ? queryString.parse(location.search) : {}

  // Handle both "?type" and "?action" query strings for business and investment login
  if (loginType.type === 'investment' || loginType.action === 'investment') {
    activeTab = 1
    pageTitle = t('Login.investmentPageTitle', { defaultValue: 'Investment Log In' })
    pagePath = '/login?type=investment'
    backgroundImagePath =
      '//images.ctfassets.net/ekto664lcskf/2kBwPFKYrPJnRWCstzj967/9af716d9b6707a34096ada22c6a5a9da/Red-Rocks-2.png?q=80&w=1400'
  }

  const staticPage = {
    title: pageTitle,
    urlPath: pagePath,
    children: [],
  }

  const setActiveTabUrl = useCallback(activeIndex => {
    if (activeIndex === 0) {
      navigate(
        normalizePagePath('/login?type=personal', {
          locale: i18n.language,
          defaultLocale: i18n.options.fallbackLng[0],
        })
      )
    }

    if (activeIndex === 1) {
      navigate(
        normalizePagePath('/login?type=investment', {
          locale: i18n.language,
          defaultLocale: i18n.options.fallbackLng[0],
        })
      )
    }
  }, [])

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: staticPage.urlPath }}
          menu={{ ...navMenus, subnav: [staticPage] }}
          showSubnav
        >
          <PageMeta language={pageContext.langKey} title={pageTitle} pathname="/login" />
          <Helmet>
            <link rel="canonical" href="https://www.elevationscu.com/login" />
          </Helmet>

          <div
            className={styles.LoginBackground}
            style={{ backgroundImage: `url('${backgroundImagePath}')` }}
          >
            <Container>
              <Login activeTab={activeTab} onSetActiveTabUrl={setActiveTabUrl} />
            </Container>
          </div>
        </Layout>
      )}
    </StaticTemplate>
  )
}

LoginPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { LoginPage }
export default LoginPage
